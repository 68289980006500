<nav [ngClass]="{'open': !(menuCollapsed | async)}" [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
  class="navbar navbar-expand-md navbar-light p-0 navbar-container" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <div class="navbar-inner-container w-100 h-100" [class.container]="!(isXsOrSm$ | async)">
    <a class="navbar-brand my-2" routerLink="/home">
      <img src="assets/images/dspace-logo.svg" [attr.alt]="'menu.header.image.logo' | translate" />
    </a>

    <div id="collapsingNav" class="w-100 h-100">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 h-100">
        <li *ngIf="(isXsOrSm$ | async) && (isAuthenticated$ | async)">
            <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
        </li>
        <li *ngFor="let section of (sections | async)">
          <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
        </li>
      </ul>
    </div>
    <div class="navbar-buttons">
      <ds-themed-search-navbar class="navbar-collapsed"></ds-themed-search-navbar>
      <ds-themed-lang-switch class="navbar-collapsed"></ds-themed-lang-switch>
      <ds-context-help-toggle class="navbar-collapsed"></ds-context-help-toggle>
      <ds-themed-auth-nav-menu class="navbar-collapsed"></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
    </div>
  </div>
</nav>
